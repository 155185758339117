import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="5.5" width="16" height="2" rx="1" fill="white" />
      <rect x="4" y="11.5" width="16" height="2" rx="1" fill="white" />
      <rect x="4" y="17.5" width="16" height="2" rx="1" fill="white" />
    </svg>
  );
};

export default MenuIcon;
