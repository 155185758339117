import React from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import Logo from "../Icons/Logo";

import "./PageHeader.css";

const PageHeader = ({ buttons }) => {
  return (
    <div
      className={classNames({ pageHeader: true, pageHeader_mobile: isMobile })}
    >
      <Logo />
      {buttons}
    </div>
  );
};

export default PageHeader;
