import { instance } from "./api";

export const apiIntentOptions = {
  getIntentOptions: (intentName) => {
    return instance.get(`/intent-options`, { params: { intentName } });
  },
  postOrder: (order) => {
    return instance.post("/orders", { order });
  },
};
