import React from "react";
import MessageBox from "../MessageBox/MessageBox";

const ImageMessageBox = ({ image, message, type }) => {
  return (
    <div className="imageMessageBox">
      <img src={image} alt="" width={200} style={{ borderRadius: "16px" }} />
      <MessageBox type={type} text={message} />
    </div>
  );
};

export default ImageMessageBox;
