import React, { useState } from "react";
import MenuIcon from "../Icons/MenuIcon";

import styles from "./Menu.module.css";

const Menu = ({ menuItems }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className={styles.menuWrapper}>
      <button
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        className={styles.menuButton}
      >
        <MenuIcon />
      </button>

      {menuIsOpen && (
        <div className={styles.menuList}>
          {menuItems.map((item, i) => (
            <div
              className={styles.menuListItem}
              key={i}
              onClick={() => {
                item.onClickAction();
                setMenuIsOpen(false);
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
