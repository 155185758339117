import React from "react";
import classNames from "classnames";
import styles from "./Button.module.css";

const Button = ({ onClick, text, isDisabled = false }) => {
  return (
    <button
      className={classNames({
        [styles.customButton]: true,
        [styles.customButton_disabled]: isDisabled,
      })}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default Button;
