import React, { useState } from "react";

import styles from "./LoginModal.module.css";

const LoginModal = ({ login, error }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <div className={styles.loginModalWrapper}>
      <div className={styles.loginModal}>
        <input
          className={styles.loginModalInput}
          type="password"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter password"
        />

        {error && (
          <span className={styles.loginModalInputErrorMessage}>{error}</span>
        )}

        <div className={styles.loginModalButtonWrapper}>
          <button
            className={styles.loginModalButton}
            type="submit"
            onClick={() => login(inputValue)}
          >
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
