import React from "react";

import "./MessageBox.css";

const MessageBox = ({ type, text }) => {
  if (!text) {
    return <></>;
  }

  return type === "question" ? (
    <div className="messageBox_blue">{text}</div>
  ) : (
    <div className="messageBox_white">{text}</div>
  );
};

export default MessageBox;
