import { io } from "socket.io-client";
import { useEffect, useState, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import axios from "axios";

import MessageBox from "./components/MessageBox/MessageBox";
import PageHeader from "./components/PageHeader/PageHeader";
import Microphone from "./components/Microphone/Microphone";
import LoginModal from "./components/LoginModal/LoginModal";
import Button from "./components/Button/Button";
import Menu from "./components/Menu/Menu";
import ImageMessageBox from "./components/ImageMessageBox/ImageMessageBox";
import { apiIntentOptions } from "./api/api-intent-options";

import "./App.css";

const App = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(undefined);
  const [allMessages, setAllMessages] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState("");
  const [textInput, setTextInput] = useState();
  const [audioResponse, setAudioResponse] = useState();

  const sttTtsOptions = useRef(null);
  const currentOptions = useRef(null);
  const currentStep = useRef(null);
  const currentAmount = useRef(null);
  const currentType = useRef(null);
  const currentItem = useRef(null);
  const socket = useRef(null);

  const bottomRef = useRef(null);

  // Images are hardcoded for demo purposes, should be defined in custom
  // backend service where images are saved and loaded from.
  const intentsWithImages = [
    { intentName: "item.banchan", image: require("./assets/김치.jpg") },
    { intentName: "item.ramyeon", image: require("./assets/라면1.jpg") },
    { intentName: "item.japchae", image: require("./assets/잡채.jpg") },
    { intentName: "item.jeonbok", image: require("./assets/전복1.jpg") },
    { intentName: "item.chicken", image: require("./assets/치킨.jpg") },
  ];

  const getImageForIntent = (intentName) => {
    return intentsWithImages.find((intent) => intent.intentName === intentName);
  };

  // Show all types of the current item (e.g fried and garlic for chicken)
  const showItemTypes = () => {
    const types = currentOptions.current.types.map((t) => t.value).join(", ");
    socket.current.emit("tts", {
      text: `유형을 선택하세요: ${types}.`,
      options: sttTtsOptions.current,
    });
  };

  // Asks the user about the amount of portions.
  const showItemAmounts = () => {
    socket.current.emit("tts", {
      text: "몇 조각을 주문하시겠습니까?",
      options: sttTtsOptions.current,
    });
  };

  // Calculates the price and sends the order to the backend.
  const finalizeOrder = async () => {
    const price = currentAmount.current * currentOptions.current.price;

    await apiIntentOptions.postOrder({
      id: "1",
      price,
      items: [
        {
          name: currentItem.current,
          amount: currentAmount.current,
          type: currentType.current,
          status: "in progress",
        },
      ],
    });

    socket.current.emit("tts", {
      text: `고맙습니다. 총 가격은 ${price}원입니다. 몇 분 안에 주문하신 음식이 준비될 예정입니다.
      감사합니다`,
      options: sttTtsOptions.current,
    });
  };

  // Intents can be categorized. In the example we categorized them by
  // the item, type and amount:
  // "item.chicken" --> item/dish
  // "type.grilled" --> type of item
  // "amount.one" --> number of item (one/1)
  const getTypeOfIntent = (intentName) => {
    return intentName.split(".")[0];
  };

  useEffect(() => {
    if (!socket.current) {
      // socket initialization
      const newSocket = io.connect(process.env.REACT_APP_BASE_URL, {
        path: "/socket",
        transports: ["websocket"],
      });

      socket.current = newSocket;

      newSocket.on("connect", () => {
        console.log("socket connected");

        // greetings message
        /*  socket.current.emit("tts", {
          text: "CIP 레스토랑에 오신 것을 환영합니다.  다음 옵션 중 하나를 선택하세요: 치킨 또는 맥주",
          options: sttTtsOptions,
        }); */
      });

      newSocket.on("disconnect", () => {
        console.log("socket disconnected");
      });

      newSocket.on("chat-stt-response", async (response) => {
        console.log(response);
        // show STT response
        setAllMessages((prevState) => [
          ...prevState,
          { type: "question", value: response.text },
        ]);

        // start order, select item
        if (response.intent && !currentOptions.current) {
          const intentType = getTypeOfIntent(response.intent.name);

          if (intentType === "item") {
            // "Options" include additional information about the available
            // types of the item, the price etc. E.g:
            // {
            //   intentName: 'item.chicken',
            //   imgUrl: '', <-- imgUrl can be set here if a storage is set up for the images
            //   types: [
            //     { intent: 'type.grilled', value: 'grilled' },
            //     { intent: 'type.garlic', value: 'garlic' },
            //   ],
            //   price: 10000,
            // },
            const options = await apiIntentOptions.getIntentOptions(
              response.intent.name
            );

            // Check if an image exists for the intent.
            const intentWithImage = getImageForIntent(response.intent.name);

            // Show image if one exists
            setAllMessages((prevState) => [
              ...prevState,
              {
                type: "answer",
                //value: response.text,
                image: intentWithImage ? intentWithImage.image : undefined,
              },
            ]);

            if (options) {
              // show item types for item
              currentOptions.current = options.data;
              currentStep.current = "type";
              showItemTypes();
              currentItem.current = response.intent.name;
            } else {
              newSocket.emit("tts", {
                text: response.intent.text,
                options: sttTtsOptions.current,
              });
            }
            // Item needs to be selected first. We show this message
            // if the user e.g says "fried" without specifying the item,
            // "chicken" first.
          } else if (intentType === "type" || intentType === "amount") {
            newSocket.emit("tts", {
              text: "먼저 항목을 선택해주세요",
              options: sttTtsOptions.current,
            });
            // Any other intentType, we just show the defined answer
          } else {
            newSocket.emit("tts", {
              text: response.intent.text,
              options: sttTtsOptions.current,
            });
          }

          // select type of item e.g "fried"/"garlic"
        } else if (response.intent && currentStep.current === "type") {
          const typeExists = currentOptions.current.types.find(
            (t) => t.intent === response.intent.name
          );

          // We check whether the response intent name from the NLP engine
          // exists in the given list of types for the item
          // e.g "type.fried"
          if (typeExists) {
            // if the type exists, we set the type and ask the user
            // how many portions they want to order.
            currentType.current = response.intent.name;
            currentStep.current = "amount";
            showItemAmounts();
          }
          // select amount
        } else if (response.intent && currentStep.current === "amount") {
          let amountNumber;

          try {
            amountNumber = Number.parseFloat(response.intent.text);
          } catch (error) {
            newSocket.emit("tts", {
              text: "금액을 다시 말씀해 주세요",
              options: sttTtsOptions.current,
            });
          }

          if (!amountNumber) {
            newSocket.emit("tts", {
              text: "금액을 다시 말씀해 주세요",
              options: sttTtsOptions.current,
            });
          }

          // If a valid number was named, the order will be finalized and sent
          // to the backend.
          if (amountNumber) {
            currentAmount.current = amountNumber;
            await finalizeOrder();
          }
        }
      });

      newSocket.on("tts-response", (response) => {
        const intentWithImage = getImageForIntent(response.intent);
        setAudioResponse(response.speech);
        setAllMessages((prevState) => [
          ...prevState,
          {
            type: "answer",
            value: response.text,
            image: intentWithImage ? intentWithImage.image : undefined,
          },
        ]);
      });
    }

    // Check if user is logged in
    if (window.localStorage.getItem("loggedIn")) {
      setIsLoggedIn(true);
    }

    // Fetch STT and TTS options like speakingRate, male/female voice etc.
    const fetchOptions = async () => {
      const options = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/stt-tts-options/5226094b-b227-4402-bb7e-6a597ce21cf6`,
      });

      return options;
    };

    fetchOptions().then((response) => {
      sttTtsOptions.current = response.data.options;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize media recoder5
  const { startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder(
    {
      audio: true,
      blobPropertyBag: {
        type: "audio/wav",
      },
    }
  );

  useEffect(() => {
    // Send audio to backend
    const fetchBlob = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();

      if (blob) {
        // blob includes the audio data
        socket.current.emit("chat", {
          audio: blob,
          options: sttTtsOptions.current,
        });
      }
    };

    if (mediaBlobUrl) {
      fetchBlob(mediaBlobUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaBlobUrl]);

  useEffect(() => {
    // Set url for playing audio
    if (audioResponse) {
      const blob = new Blob([audioResponse], { type: "audio/wav" });
      const url = URL.createObjectURL(blob);

      setAudioUrl(url);
    }
  }, [audioResponse]);

  useEffect(() => {
    if (isRecording) {
      startRecording();
      setTimeout(() => {
        setIsRecording(false);
        stopRecording();
      }, sttTtsOptions.current.chat.micIdleTimeInMs || 3000);
    } else {
      stopRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  // Scroll to the bottom automatically if messages exceed view.
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [allMessages]);

  // "Login" user
  const login = (password) => {
    if (password === process.env.REACT_APP_DEFAULT_PW) {
      setIsLoggedIn(true);
      setError("");
      window.localStorage.setItem("loggedIn", true);
    } else {
      setError("The password is wrong. Please enter the correct password.");
    }
  };

  // "Logout" user
  const logout = () => {
    window.localStorage.removeItem("loggedIn");
    setIsLoggedIn(false);
  };

  return (
    <div>
      <PageHeader
        buttons={
          isLoggedIn && !isMobile ? (
            <div className="headerButtonWrapper">
              <Button
                text="Back office"
                onClick={() => {
                  window.location.replace(process.env.REACT_APP_BACKOFFICE_URL);
                }}
              />
              <Button text="Logout" onClick={() => logout()} />
            </div>
          ) : (
            isMobile &&
            isLoggedIn && (
              <Menu
                menuItems={[
                  {
                    title: "Back office",
                    onClickAction: () =>
                      window.location.replace(
                        process.env.REACT_APP_BACKOFFICE_URL
                      ),
                  },
                  {
                    title: "Logout",
                    onClickAction: () => logout(),
                  },
                ]}
              />
            )
          )
        }
      />

      {!isLoggedIn ? (
        <LoginModal login={(value) => login(value)} error={error} />
      ) : (
        <div className="wrapper">
          <div
            className={classNames({
              messageWrapper: true,
              messageWrapper_fullscreen: isMobile,
            })}
          >
            {audioUrl && <audio controls src={audioUrl} autoPlay hidden />}

            <div className="messages">
              {allMessages.map((message, index) => (
                <div
                  key={index}
                  className={
                    message.type === "question"
                      ? "messageBox_question"
                      : "messageBox_answer"
                  }
                >
                  {message.image ? (
                    <ImageMessageBox
                      image={message.image}
                      message={message.value}
                      type={message.type}
                    />
                  ) : (
                    <MessageBox type={message.type} text={message.value} />
                  )}
                </div>
              ))}

              <div ref={bottomRef} style={{ marginTop: "24px" }}></div>
            </div>

            <div className="chatInputTextWrapper">
              <div className="chatInputFieldWrapper">
                <input
                  className="chatInputText"
                  type="text"
                  placeholder="Enter a question..."
                  onChange={(e) => setTextInput(e.target.value)}
                  value={textInput}
                />
              </div>
              <Button
                isDisabled={!textInput}
                text="Enter"
                onClick={() => {
                  socket.current.emit("chat", {
                    textInput,
                    options: sttTtsOptions.current,
                  });
                  setTextInput("");
                }}
              />
            </div>
          </div>

          <Microphone isActive={isRecording} setIsActive={setIsRecording} />
        </div>
      )}
    </div>
  );
};

export default App;
