import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-web";

import "./Microphone.css";

const Microphone = ({ isActive, setIsActive }) => {
  const [micAnimation, setMicAnimation] = useState(null);
  const lottie = useRef(null);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: lottie.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: require("./mic.json"),
    });

    setMicAnimation(animation);
  }, []);

  useEffect(() => {
    if (micAnimation) {
      if (isActive) {
        micAnimation.play();
      } else {
        micAnimation.stop();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className="microphoneWrapper">
      <div
        className="lottie"
        ref={lottie}
        onClick={() => {
          setIsActive(!isActive);
        }}
      ></div>
    </div>
  );
};

export default Microphone;
